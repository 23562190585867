<template>
  <main class="main_v8 lcl_monitoring">
    <div class="table_hat">
      <h3>LCL Monitoring</h3>
      <div class="tagselectpanel">
        <tagSelectDialog :src="vtype" @selected="vehicleSelected"></tagSelectDialog>
      </div>
    </div>

    <div class="table_monitor">
      <div class="data_table">
        <div class="info_buttons" v-dragscroll>
          <div class="info_button" :style="[category == 'MachineStatus' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('MachineStatus')">Machine Status
          </div>
          <div class="info_button" :style="[category == 'Hydraulics' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Hydraulics')">Hydraulics
          </div>
          <div class="info_button" :style="[category == 'Transfer' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Transfer')">Transfer
          </div>
          <div class="info_button" :style="[category == 'RearStation' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('RearStation')">Rear Station
          </div>
          <div class="info_button" :style="[category == 'Guidance' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Guidance')">Guidance
          </div>
          <div class="info_button" :style="[category == 'Engine1' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Engine1')">Engine 1
          </div>
          <div class="info_button" :style="[category == 'Engine2' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Engine2')">Engine 2
          </div>
        </div>
        <br>
        <livedata
            :src="url"
            :scanrate="scanrate"
            :controls=false
            @on-data="updatedata">
        </livedata>
        <div style="font-size: 1rem;" v-if="loadingData">Please wait while loading new data...</div>

        <div class="table_list">
          <template v-for = "(group, groupKey) in data" :key="groupKey">
            <div class="actual_table" v-if="!loadingData && locationid!='' && vehicleid!=0" >
              <template v-for = "(value, key) in group" :key="key">
                <div>
                  <span class="first_col">{{ key }}</span>
                  <span class="second_col" v-if="value['type']=='int16' || value['type']=='int32'">{{ value["value"] + " " + value["units"] }}</span>
                  <span class="second_col" v-if="value['type']=='bool' && value['value']>0">ON</span>
                  <span class="second_col" v-if="value['type']=='bool' && value['value']==0">OFF</span>
                </div>
              </template>
            </div>
          </template>
        </div>

      </div>
    </div>
  </main>
</template>

<script setup>
import {ref} from 'vue';
import livedata from '../components/livedata/data.vue';
import tagSelectDialog from '../components/boeing/tagSelector2.vue';
import '../components/boeing/assets/monitoring.scss';

let data = ref();
const vtype = ref("LCL");
let loadingData = ref(false);
let locationid = ref("");
let vehicleid = ref(0);
let category = ref("MachineStatus");
const scanrate = ref(5000);
const urlbase = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/equipDisplayv8.php?";
let url = ref(urlbase + "groupName=Compliance&droplet=100");
//let gdroplet = 100;

function updatedata(val) {
  if (vehicleid.value > 0) {
    data.value = val;
    loadingData.value = false;
  }
}

function switchTo(c) {
  loadingData.value = true;
  category.value = c;
  url.value = urlbase + "groupName=" + category.value + "&droplet=" + vehicleid.value;
}

function vehicleSelected(v) {
  loadingData.value = true;
  locationid.value = v.location;
  vehicleid.value = v.droplet;
  url.value = urlbase + "groupName=" + category.value + "&droplet=" + vehicleid.value;
}
</script>

<style lang="scss" scoped>
</style>